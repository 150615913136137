<template>
    <div v-if="isDataSourceCorrect" class="p-relative">

        <Toolbar 
            :menu="dataSource.Data.Menu"
            v-on:toolbar-button-click="onToolbarClick"
        />

        <DocumentPanel
            :document-info="{ ...this.dataSource.Data.Object.Resolution, __type: 'ReportResolution:#Avrora.Objects.Modules.Docflow.DocflowObjects' }"
        />

        <v-tabs
            v-model="tab"
            background-color="transparent"
            slider-size="3"
            class="tab-selector-panel"
        >
            <v-tabs-slider color="#E43958"></v-tabs-slider>

            <v-tab 
                v-for="item in pages"
                :key="item.FormId"
                @click="onTabClicked(item.FormId)"
            >
                <template v-if="item.Count < 0">
                    {{ item.Name }}
                </template> 
                <template v-else>
                    {{ item.Name }}
                    <span class="count" id="attachCounter">{{ item.Count }}</span>
                </template>   
            </v-tab>
        
        </v-tabs>

        <v-tabs-items
            v-model="tab"
            class="tab-content-panel"
        >

            <v-tab-item 
                v-for="item in pages"
                :key="item.FormId"
            >
                <template v-if="item.FormId == resolution.id">
                    <v-form class="wrapperForm" ref="form" lazy-validation>
                        <v-row dense>

                            <!--#region Реквизиты документа-->
                            <v-col cols="12" sm="12" md="6" class="section-wrapper">
                                <v-card-panel title="Реквизиты_задачи">
                                    
                                    <v-field label="Документ-основание">
                                        <template v-slot:view>
                                            <v-document-link
                                                :isViewMode="isViewMode"
                                                :id="resolution.DocumentID" 
                                                :type="'Documents.ReportGeneration.Query'"
                                                :text="`${resolution.DocumentNumber} ${document.Card.DraftNumber}`"
                                                :common="true"
                                            />
                                        </template>
                                    </v-field>
                                    
                                    <v-field
                                        v-if="svodExecuter"
                                        label="Сводящий"
                                    >

                                        <template v-slot:view>
                                            <v-enterprise-chip
                                                v-if="svodExecuter.ExecuterID == guidEmpty"
                                                :key="svodExecuter.id"
                                                :name="(svodExecuter.EnterpriseName ? svodExecuter.EnterpriseName : svodExecuter.ExecuterName)"
                                                :main="true"
                                            />
                                            <v-workplace-chip
                                                v-else
                                                :key="svodExecuter.id"
                                                :id="svodExecuter.ExecuterID"
                                                :name="svodExecuter.ExecuterName"
                                                :main="true"
                                            />
                                        </template>

                                    </v-field>

                                    <v-field label="Контрольный_срок">
                                        <template v-if="isViewMode" v-slot:view>
                                            {{ (task.is_new_record ? formatDate(task.MaxControlDate) : formatDate(task.InnerLimit)) }}
                                        </template>
                                        <template v-else v-slot:edit>
                                            <v-menu 
                                                v-model="controleDateMenu"
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="290"
                                            >
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field
                                                        :value="innerLimit | formattedDate"
                                                        prepend-icon="mdi-calendar"
                                                        readonly
                                                        v-bind="attrs"
                                                        v-on="on" 
                                                        hide-details
                                                        required
                                                        outlined
                                                        dense
                                                        class="datepick-input"
                                                    >
                                                    </v-text-field>
                                                </template>

                                                <v-date-picker
                                                    v-model="innerLimit"
                                                    @input="controleDateMenu = false"
                                                    color="teal"
                                                    :max="controlDateRange.Max"
                                                    :min="controlDateRange.Min"
                                                    :first-day-of-week="1"
                                                >
                                                </v-date-picker>
                                            </v-menu>

                                        </template>
                                    </v-field>

                                    <v-field label="Сроки_продлений">
                                        <template v-slot:view>
                                            {{ (!task.ProlongationDateList ? $t("Не_указано") : task.ProlongationDateList.replaceAll(" 0:00:00", "")) }}
                                        </template>
                                    </v-field>

                                    <v-field label="Статус">
                                        <template v-slot:view>
                                            <div class="onlyReadData more-per-lab-wrap">                                                
                                                <StatusChip :status="GetAvaibleStatuses[$helpers.getTaskStatus(task.WorkStatus)]" />
                                            </div>
                                        </template>
                                    </v-field>
                                    
                                </v-card-panel>
                            </v-col>

                            <!--#region Исполнители-->
                            <v-col v-if="executers.length > 0 || !isViewMode" cols="12" sm="12" md="12" class="section-wrapper">
                                <v-card-panel title="Исполнители">

                                    <template v-slot:titleappend>
                                        <span
                                            class="fbt-after-edit"
                                            v-if="!isViewMode"
                                            @click="onExecuterSelect"
                                        >
                                            <v-icon 
                                                small 
                                                v-tooltip.left-center="selectExecuterTooltip"
                                            >
                                                fas fa-edit
                                            </v-icon>
                                        </span>
                                    </template>

                                    <v-field
                                        label="Исполнители"
                                        :wide="true"
                                        :multi="true"
                                    >

                                        <template v-slot:view>
                                            <div v-if="!isViewMode && executers.length == 0"
                                                class="chipAreaNoData d-flex align-center pl-1"
                                            >
                                                <v-select-members-chip
                                                    class="disable-drag-wo"
                                                    target-type="Member"
                                                    @on-select="onSelectExecuterFromChip"
                                                    :items-params="executersSelectParams"
                                                    :required="true"
                                                />
                                            </div>
                                            <template v-else
                                                v-for="executer in executers"
                                            >
                                                <v-enterprise-chip
                                                    v-if="executer.ExecuterID == guidEmpty"
                                                    :id="executer.EnterpriseID"
                                                    :key="executer.id"
                                                    :name="(executer.EnterpriseName ? executer.EnterpriseName : executer.ExecuterName)"
                                                    :canDelete="!isViewMode && !executersSelectParams.implicitExclude.includes(item.EnterpriseID)"
                                                    :delFunc="onRemoveSelectedByEnterpriseId"
                                                />
                                                <v-workplace-chip
                                                    v-else
                                                    :key="executer.id"
                                                    :id="executer.ExecuterID"
                                                    :name="executer.ExecuterName"
                                                    :canDelete="!isViewMode && !executersSelectParams.implicitExclude.includes(item.ExecuterID)"
                                                    :delFunc="onRemoveSelectedByExecuterId"
                                                />
                                            </template>

                                            <v-select-members-chip
                                                v-if="!isViewMode && executers.length > 0"
                                                class="disable-drag-wo"
                                                target-type="Member"
                                                @on-select="onSelectExecuterFromChip"
                                                :items-params="executersSelectParams"
                                            />

                                        </template>

                                    </v-field>

                                </v-card-panel>
                            </v-col>

                            <!--#region Ход исполнения-->
                            <v-col v-if="isViewMode" cols="12" sm="12" md="12" class="section-wrapper">
                                <v-card-panel title="Ход_исполнения">

                                    <template v-slot:titleactions>              
                                        <div class="fbt-slider">
                                            <div class="prev-title">{{$t('Показать_все_исполнения')}}</div>
                                            <v-switch 
                                                v-model="allExecutions" 
                                                class="cust-switch" 
                                                inset 
                                                hide-details                                               
                                            >
                                            </v-switch>
                                            <div
                                                class="arrow-dropdown"
                                                @click="toggleExpanded"
                                            >
                                                <v-icon 
                                                role="button" 
                                                small 
                                                left>
                                                    {{ expanded ? "fas fa-chevron-up" : "fas fa-chevron-down" }}
                                                </v-icon>
                                            </div>
                                        </div>
                                    </template>
                                    
                                    <v-execution-tree
                                        :task-click="taskClickHandler"
                                        :task-dbl-click="taskDblClickHandler"
                                        :allExecutions="allExecutions"
                                        :expanded.sync="expanded"
                                        :is-edit-mode="!isViewMode"
                                    >
                                    </v-execution-tree>

                                </v-card-panel>
                            </v-col>

                        </v-row>
                    </v-form>
                </template>

                <template v-else>
                    <component                         
                        :is="$mapComponent(item.FormId)"
                        :ref="item.FormId"
                        :id="resolution.id"
                    />
                </template>

            </v-tab-item>

        </v-tabs-items>

        <SelectResolutionTextDlg v-if="!isViewMode" ref="SelectResolutionTextDlgRef" />

    </div>
</template>

<script>
import Toolbar from '@/components/documents/Toolbar.vue';
import DocumentPanel from '@/components/documents/DocumentPanel.vue';
import SelectResolutionTextDlg from '@/components/global/dialogs/SelectResolutionTextDlg';
import StatusChip from '@/components/global/chips/StatusChip';
import sys from '@/services/system';
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
import i18n from '@/i18n';

export default {
    name: "ReportTask",
    components: {
        Toolbar,
        DocumentPanel,
        SelectResolutionTextDlg,
        StatusChip
    },
    data() {
        return {
            tab: null,
            controleDateMenu: false,
            allExecutions: false,
            expanded: false
        }
    },
    filters: {
        formattedDate: function (value) {
            var formatedDate = sys.dateFormat(value, 'DD.MM.YYYY');
            return formatedDate ?? "";
        }
    },
    computed: {
        ...mapGetters('documents/references', ['GetAvaibleStatuses']),
        ...mapGetters('global/actionsource',
            {
                dataSource: 'getDataSource',
                dataSourceType: 'getDataSourceType'
            }),
        ...mapGetters({ 
            requiredRule: 'getRequiredRule'
        }),
        ...mapGetters('global/auth', { isCommonServiceEnabled: 'isCommonServiceEnabled' }),
        guidEmpty() {
            return sys.guidEmpty();
        },
        isDataSourceCorrect() {                
            return this.dataSourceType === 'ReportResolution';
        },
        document() {
            return this.dataSource?.Data?.Object?.Resolution?.Document;
        },
        resolution() {
            return this.dataSource?.Data?.Object?.Resolution;
        },
        task() {
            return this.dataSource?.Data?.Object?.Resolution?.Tasks?.[0]
        },
        pages() {
            let pages = 
            [
                { Count: -1, FormId: this.resolution?.id, Name: this.$t("Карточка") }
            ];

            pages = pages.concat(this.dataSource?.Data?.Object?.Pages ?? []);
            
            return pages;
        },
        svodExecuter() {
            return this.task?.Executers.filter(e => e.Svod)?.[0];
        },
        executers() {
            return this.task?.Executers
                .filter(e => !e.Svod)
                .sort((x, y) => (x.IndexInTask > y.IndexInTask) ? 1 : -1);
        },
        isViewMode() {
            return this.dataSource?.Data?.FormId == "0205021";
        },
        selectResolutionTextTooltip() {
            return this.$t("Выбрать_текст_задачи");
        },
        selectExecuterTooltip() {
            return this.$t("Выбрать_исполнителей");
        },
        controlDateRange() {
            let max = this.$moment(this.task.MaxControlDate)
            let min = this.$moment(this.dataSource?.Data?.Object?.Resolution?.ResolutionDate);

            let Max = "";
            let Min = "";

            if (max && max.isValid()) {
                Max = max.format('YYYY-MM-DD');

                if (min && min.isValid() && min.isBefore(max))                           
                    Min = min.format('YYYY-MM-DD');       
            }
            else if (min && min.isValid()) {
                Min = min.format('YYYY-MM-DD');
            }

            return { Max, Min };
        },
        innerLimit: {
            get: function() {
                if (this.task.InnerLimit)
                    return this.$moment(this.task.InnerLimit).format('YYYY-MM-DD');
                
                return this.task.InnerLimit;
            },
            set: function(v) {
                this.updateSource({ property: `Data.Object.Resolution.Tasks[0].InnerLimit`, value: `/Date(${Date.parse(v)})/` });
            }
        },
        executersSelectParams() {
            let executersToExclude = Array.from(this.task.Executers)
                .filter(x => x.WorkStatus == 16 || x.HasExecutions === true)
                .map(x => x.ExecuterID == '00000000-0000-0000-0000-000000000000' ? x.EnterpriseID : x.ExecuterID);
            let implicitExclude = [ this.resolution.SignerWorkPlaceId ].concat(executersToExclude);

            return {
                includeInner: true,
                includeOuter: this.isCommonServiceEnabled,
                includeOuterEnterprises: false,
                includeExecutersGroups: true,
                multiple: true,
                selected: Array.from(this.task.Executers)
                    .filter(x => !x.Svod)
                    .sort((x, y) => (x.IndexInTask > y.IndexInTask) ? 1 : -1)
                    .map(x => x.ExecuterID == '00000000-0000-0000-0000-000000000000' ? x.EnterpriseID : x.ExecuterID),
                implicitExclude,
                hierarchical: true
            }
        }
    },
    methods: {
        ...mapActions('documents/references', ['getReference']),
        ...mapActions('global/actionsource', { updateSource: 'updateDataSource' }),
        toggleExpanded() {
            this.expanded = !this.expanded;                          
        },
        async onToolbarClick (event, button) {
            let actions = Reflect.ownKeys(this.$store._actions).filter(i => i.includes('global/actionsource/')).map(i => { return i.split('/')[2] })

            if (actions.includes(button.Action)) {

                if (button.Action == 'Save' || button.Action == 'SaveAndClose') {

                    if (this.$refs.form[0].validate())
                        await this.$store.dispatch(`global/actionsource/${button.Action}`, {event, button});
                    else
                        this.$notify.alert(i18n.t("Не_заполнены_обязательные_поля"));
                }
                else await this.$store.dispatch(`global/actionsource/${button.Action}`, { event, button });
            }
        },
        onTabClicked (formId) {
            if (this.$refs[formId])
                this.$refs[formId][0]?.update();
        },
        async onSelectResolutionText() {
            try
            {
                let resolutionText = await this.$refs.SelectResolutionTextDlgRef.open({ title: 'Справочник_текста_резолюций' });
                this.clickedChooseItem = false;
                this.searchMatch = [];
                this.updateSource({ property: 'Data.Object.Resolution.Tasks[0].ResolutionText', value: resolutionText + ' ' });
            }
            catch (ex)
            {
                console.log(ex.message);
            }
        },
        async onExecuterSelect() {
            try
            {
                let executersToExclude = Array.from(this.task.Executers)
                    .filter(x => x.WorkStatus == 16 || x.HasExecutions === true)
                    .map(x => x.ExecuterID == '00000000-0000-0000-0000-000000000000' ? x.EnterpriseID : x.ExecuterID);
                let implicitExclude = [ this.resolution.SignerWorkPlaceId ].concat(executersToExclude);
                let currSvodRTE = JSON.parse(JSON.stringify(this.svodExecuter));
                let currSvodWEDL = [ currSvodRTE.ExecuterID, sys.sanitizeName(currSvodRTE.ExecuterName), "сотрудники", currSvodRTE.ExecuterID ];

                let selectMemberParams =  {
                    title: this.$t("Выбор_исполнителей"),
                    includeInner: true,
                    includeOuter: this.isCommonServiceEnabled,
                    includeOuterEnterprises: false,
                    includeExecutersGroups: true,
                    multiple: true,
                    selected: Array.from(this.task.Executers)
                        .filter(x => !x.Svod)
                        .sort((x, y) => (x.IndexInTask > y.IndexInTask) ? 1 : -1)
                        .map(x => x.ExecuterID == '00000000-0000-0000-0000-000000000000' ? x.EnterpriseID : x.ExecuterID),
                    implicitExclude,
                    hierarchical: true
                }

                let result = await this.$store.dispatch('global/dialogs/selectMembersNew/open', selectMemberParams);

                let currExecutersDataList = Array.from(this.task.WebExecuterDataList?.Data ?? []).reduce((acc, val) => {
                    if (result.find( x => x.employeeId ? x.workplaceId == val[0] : x.enterprise == val[0])) {
                        acc.push(val);
                    }

                    return acc;
                }, []);

                let executersDataList = result.reduce((executers, selectedExecuter) => {
                    if (!currExecutersDataList.find( x => selectedExecuter.employeeId ? x[0] == selectedExecuter.workplaceId : x[0] == selectedExecuter.enterprise)) {
                        executers.push(
                            [
                                selectedExecuter.employeeId ? selectedExecuter.workplaceId : selectedExecuter.enterprise,
                                selectedExecuter.name,
                                selectedExecuter.employeeId ? "сотрудники" : "организации",
                                selectedExecuter.workplaceId
                            ]);
                    }

                    return executers;
                }, currExecutersDataList);

                let currResolutionTaskExecuters = Array.from(this.task.Executers ?? []).reduce((acc, val) => {
                    if (result.find( x => x.type == 1 ? x.workplaceId == val.ExecuterID : x.enterprise == val.EnterpriseID))
                        acc.push(val);

                    return acc;
                }, []);

                let resolutionTaskExecuters = result.reduce((executers, selectedExecuter, index) => {
                    let existed = currResolutionTaskExecuters.find(x => selectedExecuter.type == 1 ? x.ExecuterID == selectedExecuter.workplaceId: x.EnterpriseID == selectedExecuter.enterprise);
                    
                    if (!existed)
                        executers.push(sys.prepareResolutionTaskExecuter(this.task.id, index, selectedExecuter));
                    else
                        existed.IndexInTask = index;

                    return executers;
                }, currResolutionTaskExecuters);
                
                if (currSvodRTE && currSvodWEDL) {
                    resolutionTaskExecuters.unshift(currSvodRTE);
                    executersDataList.unshift(currSvodWEDL);
                }
                
                this.updateSource({ property: 'Data.Object.Resolution.Tasks[0].Executers', value: resolutionTaskExecuters });
                this.updateSource({ property: 'Data.Object.Resolution.Tasks[0].WebExecuterDataList.Data', value: executersDataList });
            }
            catch (ex)
            {
                console.log(ex.message);
            }
        },
        taskClickHandler() {

        },
        taskDblClickHandler(item) {
            if (item.text === '2')
                this.$store.dispatch(`global/actionsource/ShowReportForm`, { executionData : { queryId: this.resolution.DocumentID, executionId: item.id } });

            //чистим выделение текста на странице, если оно случается вдруг
            if (window.getSelection)
                window.getSelection().removeAllRanges();
            else if (document.selection && document.selection.clear)
                document.selection.clear();
        },
        onSelectExecuterFromChip(e) {
            let svodIndex = this.task.Executers.indexOf(this.task.Executers.find(x => x.Svod));
            let index = svodIndex < 0 ? this.task.Executers.length + 1 : this.task.Executers.length;
            let te = sys.prepareResolutionTaskExecuter(this.task.id, index, e.value);
            let value = [ ...this.task.Executers, te];
            this.updateSource({ property: 'Data.Object.Resolution.Tasks[0].Executers', value });
        },
        onRemoveSelectedByEnterpriseId(id) {
            let executer = this.task.Executers.find(x => x.EnterpriseID === id && x.ExecuterID === sys.guidEmpty());
            let value = this.task.Executers.filter(item => item !== executer);

            if (executer.IndexInTask > 0) {
                value.forEach((x) => {
                    if (x.IndexInTask > executer.IndexInTask)
                        x.IndexInTask -= 1;
                });
            }

            this.updateSource({ property: 'Data.Object.Resolution.Tasks[0].Executers', value });
        },
        onRemoveSelectedByExecuterId(id) {
            let executer = this.task.Executers.find(x => x.ExecuterID === id);
            let value = this.task.Executers.filter(item => item !== executer);

            if (executer.IndexInTask > 0) {
                value.forEach((x) => {
                    if (x.IndexInTask > executer.IndexInTask)
                        x.IndexInTask -= 1;
                });
            }

            this.updateSource({ property: 'Data.Object.Resolution.Tasks[0].Executers', value });
        },
        update: _.debounce(function (object) {
            this.updateSource(object);
        }, 250),
        formatDate (source) {
            return this.$moment(source).format('DD.MM.YYYY');
        }
    },
    watch: {
    },
    async created() {
        (async() => {
            this.listResolutionsText = (await this.getReference({ id: 1017 })).map(item => item.Value);

            while (typeof this.$refs.form === 'undefined')
                await new Promise(resolve => setTimeout(resolve, 100));

            this.$refs?.form?.[0]?.validate();
        })();
    },
    updated() {
        if (this.dataSource !== null) {
            this.$refs?.form?.[0]?.validate();
        }
    }
}
</script>